import Banner from "../components/Banner";
import bannerData from "../data/bannerData"
import LatestAi from "../LatestAi";
import LatestTrading from "../LatestTrading";
import NextWebinar from "../NextWebinar";
import Navbar from "../components/NavBar"
import Footer from "../components/Footer";
import Popup from "../Popup";

function Home() {
    return (
        <>
            <Popup />
            <Navbar />
            <Banner data={bannerData.home} />
            <LatestAi />
            <NextWebinar />
            <LatestTrading />
            <Footer />
        </>
    );
}

export default Home;