const popupData = [
    {
        title: 'ai blog',
        content: 'Stay informed with the latest trends, insights, and breakthroughs in AI technology.',
        button: 'Explore Insights'
    },
    {
        title: 'ai tools',
        content: 'Discover powerful AI tools designed to boost productivity and elevate your projects.',
        button: 'Boost Productivity'
    }
]

export default popupData;