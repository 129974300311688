import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '30px ', background: '#fff' }}>
            <h1 style={{ color: '#000' }}>Privacy Policy</h1>
            <p style={{ maxWidth: '1200px', color: '#000' }}>
                <strong>Your Privacy is Our Priority </strong> <br />
                At our TOP 500 AI, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our website.<br /><br />
                <strong>Information We Collect </strong><br />
                We may collect the following types of personal information:<br />
                •	<strong>Personal Information:</strong> This includes information you voluntarily provide, such as your name, email address, and other contact details when you subscribe to our newsletter, comment on our posts, or contact us.<br />
                •	<strong>Usage Data:</strong> We may collect information about how you use our website, including your IP address, browser type, device information, and pages you visit.<br />
                •	<strong>Cookies and Tracking Technologies: </strong>We may use cookies and similar tracking technologies to collect information about your browsing behavior and preferences.<br /><br />
                <strong>How We Use Your Information </strong><br />
                We may use your personal information for the following purposes:<br />
                •	<strong>To provide and improve our services:</strong> We use your information to deliver content, respond to your inquiries, and enhance your user experience.<br />
                •	<strong>To communicate with you:</strong> We may send you newsletters, updates, and promotional materials. You can opt out of these communications at any time.<br />
                •	<strong>To analyze website usage:</strong> We analyze website traffic and user behavior to improve our content and services.<br />
                •	<strong>To comply with legal obligations:</strong> We may use your information to comply with applicable laws and regulations.<br /><br />
                <strong>Sharing Your Information</strong><br />
                We do not sell or rent your personal information to third parties. However, we may share your information with:<br />
                •	<strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, such as email marketing platforms, analytics providers, and website hosting services.<br />
                •	<strong>Legal Authorities:</strong> We may disclose your information to law enforcement or other government authorities if required by law or to protect our rights.<br /><br />
                <strong>Data Security</strong><br />
                We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please note that no security system is completely impenetrable.
                Your Rights
                You have certain rights regarding your personal information, including the right to:<br />
                •	Access and review your personal information<br />
                •	Request correction of inaccurate information<br />
                •	Request erasure of your personal information<br />
                •	Object to processing of your personal information<br />
                •	Restrict processing of your personal information<br />
                •	Data portability<br /><br />
                <strong>Contact Us</strong> <br />
                If you have any questions or concerns about our Privacy Policy or data practices, please contact us at info@top500ai.com . <br />
                Updates to This Privacy Policy
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a notice on our website or by sending you a direct notification.
                By using our website, you consent to the collection and use of your information as described in this Privacy Policy.
            </p>
        </div>
    );
};

export default PrivacyPolicy;