import { styled } from "@mui/material/styles"
import { Box, Button, Card, Container, Stack, Typography } from "@mui/material"
import { Filter, Height, Opacity } from "@mui/icons-material"


export const PopupMain = styled(Box)(({ theme }) => ({
    height: '2063px',
    position: 'absolute',
    top: '0',
    width: '100%',
    backgroundColor: 'rgb(0,0,0, 0.9)',
    opacity: '1',
    zIndex: '1101',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('popupTablet')]: {
        padding: '38px 15px',
        height: '2089px'
    },
    [theme.breakpoints.down('popupMobile')]: {
        padding: '38px 15px',
        height: '2106px'
    }
}))

export const PopupBackground = styled(Box)(({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    padding: '48px 15px',
    maxWidth: '1500px',
    width: '100%',
    margin: '0 auto ',
    [theme.breakpoints.down('popupTablet')]: {
        padding: '38px 15px',
        position: 'static',
    }
}))

export const PopupLogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('popupMobile')]: {
        justifyContent: 'center'
    }
}))

export const PopupContainer = styled(Container)(({ theme }) => ({
    padding: '0 !important',
    marginTop: '45px',
    [theme.breakpoints.down('popupTablet')]: {
        marginTop: '15px'
    },
    [theme.breakpoints.down('popupMobile')]: {
        marginTop: '45px'
    }
}))

export const PopupTitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: "flex-end",
    justifyContent: 'space-between',
    maxWidth: '652px',
    margin: 'auto',
    [theme.breakpoints.down('popupTablet')]: {
        maxWidth: '600px',
    },
    [theme.breakpoints.down('popupMobile')]: {
        justifyContent: 'flex-start',
        gap: '16px'
    }
}))

export const PopupTitle = styled(Typography)(({ theme }) => ({
    color: '#fff',
    fontFamily: "'Inter', sans-serif",
    fontSize: '47.8px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: ' 59.75px ',
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down('popupTablet')]: {
        fontSize: '41.8px',
    },
    [theme.breakpoints.down('popupMobile')]: {
        fontSize: '28px',
        textAlign: 'left',
        lineHeight: 'normal'
    }
}))

export const PopupTitleImage = styled('img')(({ theme }) => ({
    position: 'absolute',
    top: '-20px',
    right: '-45px',
    [theme.breakpoints.down('popupMobile')]: {
        right: 'auto',
        left: '190px'
    }

}))
export const PopupTitleAi = styled('img')(({ theme }) => ({

    [theme.breakpoints.down('popupMobile')]: {
        width: '61px '
    }

}))

export const PopupBreak = styled('br')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('popupMobile')]: {
        display: 'block'
    }
}))



export const PopupStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '158px',
    [theme.breakpoints.down('popupTablet')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
        marginTop: '40px'

    }
}))

export const PopupCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    width: '347px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    borderRadius: '8px',
    background: '#F6FFFC',
    cursor: 'pointer',
    [theme.breakpoints.down('popupTablet')]: {
        marginTop: '0',
        width: '100%',
        maxWidth: '600px'
    }
}))

export const PopupCardTitle = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    fontSize: '33.18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: ' 49.77px ',
    textTransform: 'uppercase',
    color: '#3A3A3A',
})

export const PopupContent = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    maxWidth: '350px',
    color: '#3A3A3A',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
})

export const PopupButton = styled(Button)({
    background: '#fff',
    border: '1px solid #222',
    borderRadius: '8px'
})

export const PopupButtonText = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    color: '#3A3A3A',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '25px',
    textTransform: 'capitalize'
})

// Premium Button


export const PopupPremiumCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    border: 'none',
    width: '347px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    borderRadius: '8px',
    background: "linear-gradient(120deg, #96A7FF 9.44%, #06A3CF 52.45%, #06CFA7 104.16%)",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    filter: 'blur(1px) brightness(0.5)',
    transition: '0.2s linear',
    [theme.breakpoints.down('popupTablet')]: {
        marginTop: '0',
        width: '100%',
        maxWidth: '600px',
    },
    '&:hover': {
        filter: 'blur(0px)'
    }
}))

export const PopupPremiumCardTitle = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    fontSize: '33.18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: ' 49.77px ',
    textTransform: 'uppercase',
    color: '#fff',
})

export const PopupPremiumContent = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    maxWidth: '350px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
})

export const PopupPremiumButton = styled(Button)({
    background: '#02073F',
    borderRadius: '8px'
})
export const PopupPremiumButtonText = styled(Typography)({
    fontFamily: "'Inter', sans-serif",
    color: '#fff',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '25px',
    textTransform: 'capitalize'
})

export const PopupHovered = styled(Box)({
    filter: 'blur(0px) brightness(1)',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '600',
    width: '100%',
    height: '100%',
    maxHeight: '240px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'url("/images/lock.png")',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

    '&:hover': {
        background: 'url("/images/lock_open.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    }

})

// Coming soon
export const PopupComingsoonContainer = styled(Box)({
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1000',
    width: '100vw',
    height: "100vh",
    transform: 'translateX(100%)',
    transition: 'transform 0.2s linear'
})
export const PopupComingsoon = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '965px',
    height: '356px',
    background: `linear-gradient(120deg, #96A7FF 9.44%, #06A3CF 52.45%, #06CFA7 104.16%)`,
    borderRadius: '16px',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('popupTablet')]: {
        maxWidth: '671px',
    },
    [theme.breakpoints.down('popupMobile')]: {
        maxWidth: '320px',
        height: '248px'
    }
}))

export const PopupComingsoonText = styled(Typography)(({ theme }) => ({
    fontSize: '55.8px',
    fontWeight: '700',
    lineHeight: '69.75px',
    textTransform: 'uppercase',
    fontFamily: "'Inter', sans-serif",
    [theme.breakpoints.down('popupMobile')]: {
        fontSize: '35px'
    }
}))

export const PopupComingsoonCancel = styled('img')({
    position: 'absolute',
    top: '8px',
    right: '8px',
    cursor: 'pointer'
})

export const PopupComingsoonStars = styled('img')(({ theme }) => ({
    width: '160px',
    position: 'absolute',
    bottom: "0",
    right: '0',
    opacity: "0.25",
    [theme.breakpoints.down('popupMobile')]: {
        width: '90px'
    }
}))

export const PopupComingsoonStarsSecond = styled('img')(({ theme }) => ({
    width: '115px',
    position: 'absolute',
    bottom: "0",
    right: '0',
    opacity: "0.25",
    [theme.breakpoints.down('popupMobile')]: {
        width: '90px'
    }
}))

